@import './variables';

.dropzone-wrapper {

  cursor: pointer;

  .dropzone-contents {

    position: relative;

    & > [mode="indeterminate"] {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;

      &.not-uploading-files {
        opacity: 0;
      }

    }

    border: 1px dashed $grey-400;
    background: $grey-50;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-weight: bold;
    }

    img {
      margin-top: 10px;
    }
  }

  &.dropzone-drag {

    .dropzone-contents {

      border: 1px dashed $primary-blue;
      background: $grey-50;

    }
  }

  .select-file-button {

    margin: 15px 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

  }

  padding-bottom: 10px;

}


.dialog-box{
  .not-uploading-files{
    display: none!important;
  }
}

.folder-list-item{
  &>div{
    &>div{
      &>div{
        &:nth-of-type(2), &:nth-of-type(3){
          font-family: 'FFMaxWebProLight', 'Oxygen', sans-serif;
        }
      }
    }
  }
}
