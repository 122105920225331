@import '../variables';

.settings-page {

  box-sizing: border-box;

  padding: 10px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: $size-sm-max) {
    width: 100vw;
    min-height: 100vh;
  }

  .settings-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    h3 {
      margin-right: 15px;
    }
  }

  .settings-blocks {

    .settings-block {

      & + .settings-block {
        margin-top: 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 15px;
      }

      .settings-block-group {

        &:first-child {
          padding-top: 15px;
        }

        & + .settings-block-group {
          margin-top: 15px;
          border-top: 1px dashed rgba(0, 0, 0, 0.05);
          padding-top: 15px;
        }
      }

    }
  }

}
