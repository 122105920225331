@import 'variables';

.flex-grid {

  display: flex;
  flex-direction: column;

  &.row {
    flex-direction: row;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.xs-sm-col {

    @media (max-width: $size-sm-max) {
      flex-direction: column;
    }

  }

  &.xs-sm-col-reverse {

    @media (max-width: $size-sm-max) {
      flex-direction: column-reverse;
    }
  }

  &.flex-children > * {
    flex: 1;
  }

  .flex {
    flex: 1;

    &.flex-66 {
      flex: 66;

      &:first-child:last-child {
        width: auto;
      }

    }

    &.flex-33 {
      flex: 33;

      &:first-child:last-child {
        width: auto;
      }
    }
  }

  &.xs-sm-no-flex {

    @media (max-width: $size-sm-max) {
      .flex {
        flex: none;
      }
    }

  }

}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}

.flex-justify-content-center {
  justify-content: center;
}
