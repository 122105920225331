@import './variables';

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  font-size: 16px;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  color: #2d2d2d;
  background-color: #ffffff;
  font-family: 'FFMaxWebProLight', 'Oxygen', sans-serif;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
  max-width: 100vw;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: 1em;
}
//h1,
//h3,
//h5 {
//  font-family: 'Helvetica Neue', 'HelveticaNeueLT-Roman', 'Open Sans', sans-serif;
//}
h1 {
  font-size: 40px;
  margin: 30px 0;
}
h2 {
  color: #777777;
  font-size: 30px;
  margin: 20px 0;
}
h3 {
  font-size: 20px;
  margin: 15px 0;
}
h4 {
  font-size: 17px;
}


a,
a:active,
a:visited,
a:focus {
  color: #777777;
  text-decoration: none;
}
a:hover {
  color: #777777;
  text-decoration: underline;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

a[href] {
  cursor: pointer;
}

.white-text-icon-button {

  min-width: 34px !important;

  .label-wrapper {
    color: white;
    fill: white;
    text-transform: uppercase;
    position: relative;
    opacity: 1;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0;
    user-select: none;
    padding-left: 16px;
    padding-right: 16px;

    svg {
      height: 34px !important;
      fill: white !important;
    }
  }
}
