@import 'variables';

.page-wrapper {

  &,
  .main-section,
  .page-content {

    position: sticky;
    top: 0;

    min-width: 100vw;
    min-height: calc( 100vh - #{$header-height} );

  }

}

.fas, .svg-inline--fa {
  min-width: 20px;
  min-height: 20px;
}

.loading-placeholder {
  display: flex;
  flex: 1;
  min-height: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dayView {
  padding: 8px 16px;

  .empty {
    text-align: center;
  }

  .calendar-day-visits {
    columns: 6 180px;

    .visit {
      break-inside: avoid;
      margin-bottom: 32px;
    }
  }
}

/*
.visit-details-inner-section,
.visit-product-remaining {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
  transition-delay: 0.1s;
}

.visit:hover {
  .visit-details-inner-section,
  .visit-product-remaining {
    opacity: 1;
    max-height: 100px;
  }
}
*/
