.health-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  .flex-it {
    display: block !important;
    flex: 1 100% !important;

    input {
      width: 100% !important;
    }
  }

  @media(min-width: $size-md-min) {
    .big-label {
      label {
        transform: scale(0.75) translate(0px, -40px) !important;
      }
    }
  }

  .checkbox-wrapper {
    padding-bottom: 10px;
  }

  width: 100%;

  & > * {
    //width: calc( 100% / 3 ) !important;

    &:not(:first-child) {
      margin-left: 5px;
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  @media (max-width: $size-sm-max) {

    .checkbox-wrapper {
      padding-bottom: 0;
    }

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .flex-it {
      flex: none;
    }

    & > * {
      width: 100% !important;
      margin: 5px 0;
    }

    & + .health-row {
      margin-top: 15px;
      padding-top: 0px;
      border-top: 1px dashed $grey-500;
    }

  }
}
