@import "../variables";

.view-job-page-wrapper {
  box-sizing: border-box;

  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > .header {
    padding: 0 0 20px;

    h2 {
      padding-left: 5px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: $size-sm-max) {
    min-height: 100vh;
  }

  .map-wrapper,
  .map-wrapper > .map,
  .map-wrapper > .map > .map-inner-wrapper,
  .map-wrapper > .map > .map-inner-wrapper > .actual-map {
    min-height: 150px !important;
    min-width: 300px !important;
    max-width: 90vw !important;

    @media (min-width: $size-md-min) {
      min-height: 400px !important;
    }
  }

  .details {
    display: flex;
    flex-direction: row;

    @media (max-width: $size-sm-max) {
      flex-direction: column;
    }

    table {
      width: 100%;

      tr {
        position: relative;

        th,
        td {
          text-align: left;
          padding: 4px 0;

          &:nth-child(2) {
            padding-left: 45px;
          }

          .italic {
            font-style: italic;
          }

          &.icon-cell {
            padding: 0 0;
            position: absolute;
          }

          @media (max-width: $size-sm-max) {
            &:first-child:not(.divider) {
              display: none;
            }
            &:nth-child(2) {
              padding-left: 0;
            }
          }

          line-height: 1.5;
        }

        .product-usage {
          font-style: italic;
          font-size: 0.8em;
        }

        th {
          font-family: Roboto, Noto, sans-serif;
          padding-right: 10px;
          font-weight: bold;
          text-align: left;
          color: rgb(0, 0, 0);
        }

        td.divider {
          padding: 0 20px 0 10px;

          hr {
            border-style: dashed;
            border-width: 0.25px;
            border-bottom: none;
            border-color: $grey-300;
          }
        }
      }
    }

    .info,
    .site-map {
      width: 50%;
      display: flex;
      flex-direction: row;

      .show-small {
        display: none;
      }

      @media (max-width: $size-sm-max) {
        width: 100%;

        box-sizing: border-box;

        &.site-map {
          display: none;
        }

        .show-small {
          display: block;
        }

        td.show-small,
        th.show-small {
          display: table-cell;
        }

        tr.show-small {
          display: table-row;
        }
      }

      & > .inner {
        padding: 5px 0;
        flex: 1;
        display: flex;
        flex-direction: column;

        .comments {
          // padding-left: 45px;
          // padding-right: 20px;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }

    .site-map {
      flex-direction: column;

      .map-wrapper {
        width: 100%;
      }

      table {
        margin-top: 20px;
      }
    }
  }

  .additional {
    margin-top: 10px;
    border-top: 1px dashed $grey-300;

    display: flex;
    flex-direction: row;

    @media (max-width: $size-sm-max) {
      flex-direction: column;
    }

    .site-visits {
      box-sizing: border-box;
      width: 50%;
      display: flex;
      flex-direction: column;

      @media (max-width: $size-sm-max) {
        width: 100%;
      }

      .file-table {
        padding-top: 15px;
        width: 100%;

        display: flex;
        flex-direction: column;

        .file-row {
          display: flex;
          flex-direction: row;
          padding: 10px 0;
          width: 100%;

          &:not(:first-child) {
            border-top: 1px dashed $grey-200;
          }

          .file-icon {
            font-size: 1.5em;
          }

          .file-details {
            padding: 0 10px;
            flex: 1;
            display: flex;
            flex-direction: column;

            .file-name {
              cursor: pointer;
            }

            .file-size {
              padding-top: 2px;
              font-family: Roboto, Noto, sans-serif;
              font-size: 0.7em;
              font-style: italic;
            }
          }
        }
      }
    }

    .site-visits {
      padding-left: 45px;
      padding-right: 20px;

      @media (max-width: $size-sm-max) {
        padding: 0 0;
      }

      .visit + .visit {
        margin-top: 15px;
      }
    }
  }

  .comment-section {
    // padding-right: 20px;
    // padding-left: 45px;

    @media (max-width: $size-sm-max) {
      padding: 0 0;
    }
  }
  .visit-product-remaining {
    // padding-bottom: 50px;
    .visit-product-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > div {
        width: 50%;
      }
    }
  }
}

.documents {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: $size-sm-max) {
    width: 100%;
  }

  .file-table {
    padding-top: 15px;
    width: 100%;

    display: flex;
    flex-direction: column;

    .file-row {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 10px 25px;
      width: calc(100% - 30px);

      &:not(:first-child) {
        border-top: 1px dashed $grey-200;
      }

      .file-icon {
        font-size: 1.5em;
      }

      .file-icon-anchor {
        display: block;

        * {
          pointer-events: none;
        }
      }

      .file-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .file-details {
        padding: 0 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 220px);

        .file-name {
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
        }

        .file-size {
          padding-top: 2px;
          font-family: Roboto, Noto, sans-serif;
          font-size: 0.7em;
          font-style: italic;
        }
      }
    }
  }
}

.comment-section {
  .comment {
    justify-content: center;

    .comment-content-text {
      padding: 5px 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .comment-title {
      padding-top: 2px;
      font-family: Roboto, Noto, sans-serif;
      font-size: 0.7em;
      font-style: italic;
    }

    & + .comment {
      border-top: 1px dashed $grey-200;
    }

    &:first-child {
      margin-top: 10px;
    }
  }

  .submit-button {
    margin-left: 5px;
  }
}

.handle {
  cursor: move;
  opacity: 0.7;
}
