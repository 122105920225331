.floor-plan-wrapper {

  .floor-plan-image {
    max-width: 90%;
    max-height: 90%;
  }

}

.floor-plan-modal {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;

  @media (max-width: 999px) {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .refrain-notice {
    font-size: 0.7rem;
    text-align: center;
    color: rgba(0,0,0,0.4);
  }

  .floor-plan-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 10px 10px;

    width: 100%;

    flex-wrap: wrap;

    .control-group + .control-group {
      padding-left: 15px;

      @media (max-width: 999px) {
        padding-left: 5px;
      }
    }

    .control {

      margin-top: 10px;

      @media (max-width: 999px) {
        min-width: 42px !important;
      }

      &.image-control {

        min-width: 42px !important;

        img {
          max-height: 40%;
          margin: 0 5px;
        }

      }

    }

    @media (max-width: 999px) {
      .presentation-wording {
        display: none !important;
      }
    }

  }

}
