.user-edit {

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  & > * {
    width: 100% !important;
  }

}

.user-edit-dialog {


  .action-button + .action-button {
    margin-left: 10px;
  }

}
