@import '../variables';

.users-page {

  box-sizing: border-box;

  padding: 10px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: $size-sm-max) {
    width: 100vw;
    min-height: 100vh;
  }

  .users-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }

  .users-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    h3 {
      margin-right: 15px;
    }
  }

  .users-list {

    display: flex;
    flex-direction: column;

    .user {
      display: flex;
      flex-direction: row;

      padding: 0;

      .user-marker {
        width: 10px;
        margin-right: 10px;
      }

      .user-controls {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {

          &:not(:last-child) {
            margin-right: 10px;
          }

        }
      }

      .user-content {
        flex: 1;

        padding: 10px 0;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .username,
        .name {
          font-weight: bold;
        }

        .name + .username {
          margin-top: 5px;
        }

        .role {
          margin-top: 5px;
          font-size: 0.8em;
          font-style: italic;
        }
      }

      & + .user {
        border-top: 1px dashed $grey-400;
      }

    }
  }
}

.draggable-mirror {
  background-color: rgba(255, 255, 255, 0.8);
}
