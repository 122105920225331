@import '../variables';

.notifications-page {
  box-sizing: border-box;
  padding: 10px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: $size-sm-max) {
    width: 100vw;
    min-height: 100vh;
  }

  .notifications-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }

  .notifications-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    h3 {
      margin-right: 15px;
    }
  }


  .notifications-list {

    display: flex;
    flex-direction: column;

    .notification {
      display: flex;
      flex-direction: row;

      .notification-content {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & + .notification {
        border-top: 1px dashed $grey-400;
      }

    }
  }
}
