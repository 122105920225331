@import "../variables";

.site-visits .visit + .visit {
  margin-left: 0;
}

.site-visits .count {
  font-size: 11px;
  padding-bottom: 4px;
  margin-top: 12px;
  color: #aaa;
}

.visit.unscheduled {
  position: relative;

  .unscheduled-indicator {
    width: 100%;
    text-align: center;
    font-weight: bold;

    position: absolute;
    top: 0;
    left: 0;

    background: repeating-linear-gradient(
      -55deg,
      rgba(255, 0, 0, 0.7),
      rgba(255, 0, 0, 0.7) 10px,
      rgba(255, 0, 0, 0.3) 10px,
      rgba(255, 0, 0, 0.3) 20px
    );

    box-sizing: border-box;

    padding: 7.5px 5px 5px 5px;
    color: white;

    & + .visit-inner {
      padding-top: 10px;
    }
  }

  &.sidebar-visits {
    .unscheduled-indicator + .visit-inner {
      padding-top: 25px;
    }
  }
}

.visit {
  border: 3px solid rgba(200, 200, 200, 0.8);
}

.visit-page {
  padding: 10px 20px;
  box-sizing: border-box;

  h3 {
    padding-left: 0;
    padding-top: 5px;
    margin: 0;
  }

  @media (max-width: $size-sm-max) {
    width: 100vw;
    min-height: 100vh;
  }

  canvas {
    //cursor: none !important;
  }

  .floor-plan {
    .floor-plan-image {
      max-width: 100%;
    }

    & + .floor-plan {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px dashed $grey-400;
    }
  }

  .editor {
    display: flex;
    flex-direction: row;

    @media (max-width: $size-sm-max) {
      flex-direction: column;
    }

    .editor-details {
      display: flex;
      flex-direction: column;
      padding-right: 5px;

      @media (max-width: $size-sm-max) {
        padding-right: 0;
      }

      .product-applied {
        padding-top: 10px;
      }

      .product {
        display: flex;
        flex-direction: column;

        padding: 10px 0;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

        & > label:first-child {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .time-details {
          display: flex;
          flex-direction: row;

          & > * {
            width: calc(100% / 2) !important;
            box-sizing: border-box;

            &:not(:first-child) {
              margin-left: 5px !important;
            }
            &:not(:last-child) {
              margin-right: 5px !important;
            }
          }
        }

        .product-details {
          display: flex;
          flex-direction: row;

          & > * {
            width: calc(100% / 3) !important;
            box-sizing: border-box;

            &:not(:first-child) {
              margin-left: 5px !important;
            }
            &:not(:last-child) {
              margin-right: 5px !important;
            }
          }
        }
      }
    }

    .editor-health {
      display: flex;
      flex-direction: column;
      padding-left: 5px;

      @media (max-width: $size-sm-max) {
        padding-right: 0;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    h4 {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .documents {
      width: 100%;
    }

    .editor-details,
    .editor-health {
      width: 50%;

      @media (max-width: $size-sm-max) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .comment-section {
    padding-top: 15px;
  }
}

.visit-edit-area {
  .product {
    width: 100%;

    & > *,
    input {
      width: 100% !important;
    }
  }
}

.edit-visit-state {
  margin-top: -15px;
  border-bottom: 1px dashed #aaa;
  padding-bottom: 10px;
}

.edit-visit-products {
  max-height: 60vh;
  overflow-y: scroll;
  padding: 10px 0;

  .edit-product {
    border-bottom: 1px dashed #aaa;
    margin-bottom: 10px;
    padding-bottom: 5px;
    margin-top: -15px;

    .product-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .edit-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    min-height: 72px;
    margin-top: -15px;

    & > * {
      width: 120px !important;

      & + * {
        margin-left: 20px !important;
        position: relative;

        &:before {
          content: "→";
          display: block;
          position: absolute;
          right: calc(100% + 3px);
          top: 25%;
        }
      }
    }
  }

  .edit-estimated-area {
    margin-top: -15px;
  }

  .edit-plan {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > * {
      margin-top: -15px;
    }

    .with-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > :last-child {
        margin-left: 10px;
      }
    }
  }

  .edit-applicators {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .edit-applicator {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: -15px;

      & > .actions {
        margin-left: 10px;

        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.site-visit-output-modal-body {
  padding: 0 !important;
  height: 70vh !important;
  min-height: 70vh !important;
}

.site-visit-output-modal-body .timeline {
  height: 70vh !important;
  overflow-y: scroll;
}

.site-visit-output-modal-body .tabs-container .timeline {
  height: 60vh !important;
}
