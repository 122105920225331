@import '../variables';

.calendar {

  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;

  .calendar-week {

    position: relative;

    border-top: 2px solid $primary-blue;

    &:last-child {
      border-bottom: 2px solid $primary-blue;
    }

    width: 100%;
    display: flex;
    flex-direction: row;

    &:first-child{
      position: sticky;
      top: 130px;
      background: white;
      z-index: 9999;
    }

    &.calendar-week-date {

      @media (min-width: $size-md-min) {
        position: sticky;
        top: 162px;
        z-index: 100;
      }


      @media (max-width: $size-sm-max) {
        display: none;
      }
    }

    .calendar-day,
    .calendar-date {

      border-left: 1px solid $grey-100;
      border-right: 1px solid $grey-100;
      padding-left: 2px;
      padding-right: 2px;

      &:not(.title):not(.calendar-date) {

        min-height: calc( calc( calc( 100vh - calc( #{$header-height} * 2 ) ) - 30px ) / 5 );

        @media (min-width: $size-md-min) {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        @media (max-width: $size-sm-max) {
          min-height: 1px;
          padding-bottom: 0;
        }
      }


      &:nth-child(6):not(.empty),
      &:nth-child(7):not(.empty) {
        background: repeating-linear-gradient(
                        -55deg,
                        white,
                        white 10px,
                        rgb(235, 235, 235) 10px,
                        rgb(235, 235, 235) 20px
        );
      }

      @media (max-width: $size-sm-max) {

        &.current {
          background: transparentize($secondary-pink, 0.5);
        }
        &[data-has-visits="true"] {
          background: transparentize($primary-blue, 0.8);
        }

        cursor: pointer;

      }

      &.title {
        text-align: center;
        line-height: 30px;
        font-weight: bold;
        font-size: 1.2em;
        color: black;

        .title-normal {
          display: inline;
        }

        .title-small {
          display: none;
        }

        @media (max-width: $size-sm-max) {
          .title-normal {
            display: none;
          }

          .title-small {
            display: inline;
          }
        }
      }

      &.empty {
        background-color: $grey-200;
        border-left-width: 0;
        border-right-width: 0;
      }

      &:first-child {
        border-left-width: 2px;
      }

      &:last-child {
        border-right-width: 2px;
      }

      width: calc( 100% / 7 );
      box-sizing: border-box;

      .date,
      &.calendar-date {

        padding: 15px;

        @media (min-width: $size-md-min) {
          background: white;
          border-bottom: 0;
          padding: 2.5px;

          &.date {
            display: none;
          }
        }

        display: flex;
        justify-content: space-between;

        @media (max-width: $size-sm-max) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        span {
          height: 30px;
          box-sizing: border-box;
          display: inline-block;
          text-align: center;
          padding: 4px;
          border-radius: 100%;
          border: 2px solid transparent;
          line-height: 22px;


          &.top-icon-set{
            width: 70px;
            display: flex;
            justify-content: space-between;
            .notification-dot{
              background-color: $secondary-pink;
              border-radius: 50%;
              color: white;
              line-height: 14px;
              width: 23px;
              height: 23px;
              font-size: 12px;
            }
          }
        }

        &.current span {
          border-radius: 100%;
          border-color: #ff0092;
        }


        &:nth-child(6):not(.empty),
        &:nth-child(7):not(.empty) {
          background: repeating-linear-gradient(
                          -55deg,
                          white,
                          white 10px,
                          rgb(235, 235, 235) 10px,
                          rgb(235, 235, 235) 20px
          );
        }


      }

      .visit {

        padding: 0px!important;

        @media (max-width: $size-sm-max) {
          display: none;
        }

        &:not(:hover) {

          cursor: pointer;

          .visit-product-remaining,
          .visit-controls {
            // display: none !important;
          }

        }
      }

    }

  }

}
