.reports {


  box-sizing: border-box;

  padding: 10px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .reports-header {
    display: flex;
    flex-direction: row;

    align-items: center;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    h3 {
      margin-right: 15px;
    }
  }

  .settings-configuration {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }

  .generate-row {
    margin-top: 10px;
  }

}
