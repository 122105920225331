@import '../variables';

.edit-job {

  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  .general-info,
  .site-info {
    display: flex;
    flex-direction: column;
    width: 50%;

    & > * {
      width: 100% !important;
      margin: 5px 0 !important;
    }
  }

  .general-info {
    padding-right: 5px;
  }

  .site-info {
    padding-left: 5px;
  }

  .divider {
    border-top: 1px dashed $grey-400;
    margin: 20px 0 5px !important;
  }

  .contact-details,
  .address-additional {

    display: flex;
    flex-direction: row;

    & > * {
      width: calc(100% / 3) !important;

      &:not(:first-child) {
        margin-left: 5px !important;
      }
      &:not(:last-child) {
        margin-right: 5px !important;
      }
    }
  }


  .contact-details {

    & > * {
      width: calc(100% / 2) !important;
    }

  }

  .default-applicator-selection {

    display: flex;
    flex-direction: row;
    align-items: center;

    .flat-button {
      margin-left: 10px !important;
    }
  }

  .edit-job-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-field-flex {
      flex: 1;
    }

    & > * {

      &:not(:first-child) {
        margin-left: 5px !important;
      }

      &:not(:last-child) {
        margin-right: 5px !important;
      }
    }
  }

}
