@import '../variables';

.app-bar {

  position: sticky !important;
  top: 0;
  box-sizing: border-box;

  .brand-icon-section{
    &>div{
      display: none!important;
    }
  }
  @media (max-width: $size-xs) {
    flex-direction: column;
    &>h1{
      display: none;
    }
    &>div:first-child{
      margin-top: 0px!important;
      margin-right: 0px!important;
    }

    .brand-icon-section{
      display: flex;
      align-items: center;
      justify-content: space-between;
      &>div{
        display: inline-block!important;
      }


    }
    .app-bar-icons{
      margin-left: -24px;
      justify-content: space-between!important;
      &>div{
        display: none!important;
      }
    }

  }


  img.logo {
    max-height: 48px;
    height: 90%;
    margin-left: 8px;
  }

  .app-bar-icons {

    position: sticky;
    top: 0;

    height: $header-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: $size-md-max) {

      button {
        min-width: 0 !important;
        padding: 0 10px !important;

        svg {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        span {
          display: none !important;
        }
      }

      padding-right: 5px !important;
    }

    .notifications-button.notifications-active {

      &,
      button,
      svg {
        fill: $secondary-pink !important;
        color: $secondary-pink !important;
      }
    }

  }

  svg[data-notifications="true"] {
    //fill: $secondary-pink !important;
  }

  @media (max-width: 767px) {
    .hide-mobile-menu {
      display: none;
    }
  }

}
