$header-height: 64px;

$primary-blue: #1db3e7;
$secondary-pink: #ff4081;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #BDBDBD;
$grey-500: #9E9E9E;

$grey: $grey-100;

$size-lg-min: 1200px;
$size-lg: $size-lg-min;

$size-md-min: 992px;
$size-md: $size-md-min;
$size-md-max: $size-lg-min - 1;

$size-sm-min: 768px;
$size-sm: $size-sm-min;
$size-sm-max: $size-md-min - 1;

$size-xs: 480px;
$size-xs-max: $size-sm-min - 1;
