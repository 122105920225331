.job-list {

  &-search-wrapper {
    padding: 15px 15px 0;
    display: flex;
    flex-direction: row;

    .flex {
      flex: 1;
    }

    .button-wrapper {
      padding-left: 15px;
    }
  }


  &-filter-wrapper {
    padding: 15px 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    flex-wrap: nowrap;

    p {
      padding-right: 5px;
    }

    label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 10px;

      & > span {
        margin-left: -10px;
      }
    }
  }

  &-wrapper {

    .jobs {

      padding: 0 15px;

      .job {

        &:first-child {
          padding-top: 15px;
        }
        &:last-child {
          padding-bottom: 15px;
        }

        & + .job {
          padding-top: 15px;
          margin-top: 15px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        table {

          tr {

            th, td {
              text-align: left;
              padding-right: 15px;
              line-height: 1.3;
            }

            th {
              font-weight: 900;
              color: black;
            }

          }

        }
      }

    }

  }

  &-loading-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
  }

}
