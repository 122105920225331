@import '../variables';

.create-job-page {

  padding: 10px 20px;
  box-sizing: border-box;

  h3 {
    padding-left: 0;
    padding-top: 5px;
    margin: 0;
  }

  @media (max-width: $size-sm-max) {
    width: 100vw;
    min-height: 100vh;
  }

}
