@font-face {
  font-family: 'HelveticaNeueLT-Bold';
  src: url('/fonts/2FC8A1_0_0.eot');
  src: url('/fonts/2FC8A1_0_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/2FC8A1_0_0.woff2') format('woff2'),
  url('/fonts/2FC8A1_0_0.woff') format('woff'),
  url('/fonts/2FC8A1_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueLT-Roman';
  src: url('/fonts/2FC8A1_1_0.eot');
  src: url('/fonts/2FC8A1_1_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/2FC8A1_1_0.woff2') format('woff2'),
  url('/fonts/2FC8A1_1_0.woff') format('woff'),
  url('/fonts/2FC8A1_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'FFMaxWebProLight';
  src: url('/fonts/2FC8A1_2_0.eot');
  src: url('/fonts/2FC8A1_2_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/2FC8A1_2_0.woff2') format('woff2'),
  url('/fonts/2FC8A1_2_0.woff') format('woff'),
  url('/fonts/2FC8A1_2_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Campton-Bold';
  src: url('/fonts/2F8464_0_0.eot');
  src: url('/fonts/2F8464_0_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/2F8464_0_0.woff2') format('woff2'),
  url('/fonts/2F8464_0_0.woff') format('woff'),
  url('/fonts/2F8464_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Campton-Light';
  src: url('/fonts/2F8464_1_0.eot');
  src: url('/fonts/2F8464_1_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/2F8464_1_0.woff2') format('woff2'),
  url('/fonts/2F8464_1_0.woff') format('woff'),
  url('/fonts/2F8464_1_0.ttf') format('truetype');
}
