@import './calendar';
@import './visit';
@import './jobs';

.dashboard {

  width: 100vw;

  $sidebar-width: 100vw;
  $sidebar-width-1: 100vw;
  $sidebar-width-2: 100vw;

  display: flex;
  flex-direction: column;

  &.visit-page{
    flex-direction: row;
  }

  @media (max-width: 1450px) {
    .hide-on-2 {
      display: none !important;
    }
  }

  .sidebar-header-with-hide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &:not(.list-dashboard) {

    @media (max-width: $size-sm-max) {
      .sidebar-wrapper {
        display: none !important;
      }

      .sidebar-display-button {
        display: none !important;
      }

      .visit {

        cursor: pointer;

        .visit-controls {
          // display: none;
        }

      }
    }


  }

  .calendar-wrapper {

    width: calc( 100vw );

    @media (max-width: 1600px) {
      width: calc( 100vw )
    }

    @media (max-width: 1450px) {
      width: calc( 100vw )
    }


    @media (max-width: $size-sm-max) {
      width: 100%;
    }

    .remote-calendar {
      &[data-filters*="Unticked QA"] {
        [data-filter-qa] {
          display: none !important;
        }
      }

      &[data-filters*="Unticked INF"] {
        [data-filter-inf] {
          display: none !important;
        }
      }

      &[data-filters*="Unbilled"] {
        [data-filter-billed] {
          display: none !important;
        }
      }

      &[data-filters*="PM Status Check"] {
        [data-filter-pm=false] {
          display: none !important;
        }
      }
    }

  }
  .sidebar-actions,
  .calendar-actions {

    padding: 15px;
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top:0px;
    background: white;
    z-index: 99999;

    .search-area{
      display: flex;
      flex:1;
      align-items: center;

      border: 1px solid #979797;
      padding-left: 10px;

      @media(max-width: $size-xs){
        display: none!important;
      }
    }
    .calendar-actions-button-section{
      display: flex;
      flex:1;
      align-items:center;

    }

    .dashboard-actions-right {

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .applicator-selection {
        display: inline-block;
      }

    }

    @media(max-width: $size-sm-max){
      flex-direction: column;
      .search-area{
        width: 100%;
        display: none;
      }
      .calendar-actions-button-section{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }


    }

    h3 {
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;

      position: relative;

      .progress {
        position: absolute !important;
        right: -40px;
        margin-left: 10px;
      }

      &:first-child:last-child {
        width: 100%;
        text-align: center;
      }
    }

    .search-area{
      display: none;
    }

  }

  .sidebar-wrapper {


    @media (max-width: $size-md-max) {

      .hideable-small {
        display: none !important;
      }

    }

    width: $sidebar-width;

    @media (max-width: 1600px) {
      width: $sidebar-width-1;
    }

    @media (max-width: 1450px) {
      width: $sidebar-width-2;
    }

    // min-height: 100vh;

    .sidebar {

      min-height: 100%;

      .sidebar-contents {
        margin: 0 4px;
        border-top: 2px solid $secondary-pink;
        padding: 8px;
        display: flex;
        .visit-product-remaining{
          display: none;
        }
        .sidebar-visits{
          min-width: 260px;
        }
      }


    }

  }

  &.visit-page {
    .calendar-actions{


    @media (max-width: $size-sm-max){

        flex-direction: column!important;
        .search-area{
          order:1;
          display: flex;
          margin-bottom: 10px;
          width:97%;
        }
      }

      .action-list{
        order:2;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .job-list{
      .visit-controls{
        @media (max-width: $size-sm-max){

          display: none;
        }
      }
    }

  }

  &.dashboard-page{
    .calendar-actions{


      @media (min-width: $size-md-min) {
        position: sticky;
        top: 64px;
      }

      .search-area{
        display: flex;
        margin-left: 10px;
      }
    }
    .sidebar-wrapper {
      // border-top: 2px solid $secondary-pink;
      background: white;
      padding-top: 10px;

      .sidebar{
        background: #EEEEEE;

        border-top: 2px solid $secondary-pink;
        border-bottom: 2px solid $secondary-pink;

        .sidebar-actions{
          background: #EEEEEE;
          padding: 5px 5px 3px;
          h3{
            font-size: 14px;
            padding-left: 10px;
          }
        }
        .sidebar-contents {
          max-height: 150px;
          border-top: 0px;
          .visit{
            background-color: #6D6D6D!important;
            *{
              color: white;
            }

            .visit-inner{
              .visit-controls{
                // display: none!important;
              }
              .visit-details{
                .visit-name-section{
                  padding-top: 8px;
                  padding-bottom: 5px;
                  font-weight: bolder;
                }
              }
            }
            .comment-section{
              display: none;
            }
          }
        }
      }
    }

    .calendar-wrapper{
      .visit{

        .visit-inner{
          .visit-controls{
            // display: none!important;
          }
        }
      }
    }
  }

  &.visit-page{
    .sidebar-wrapper {


      @media (max-width: $size-md-max) {

        .hideable-small {
          display: none !important;
        }

      }

      width: $sidebar-width;

      @media (max-width: 1600px) {
        width: $sidebar-width-1;
      }

      @media (max-width: 1450px) {
        width: $sidebar-width-2;
      }

      // min-height: 100vh;

      .sidebar {

        min-height: 100%;

        .sidebar-contents {
          margin: 0 4px;
          border-top: 2px solid $secondary-pink;
          padding: 8px;
          display: flex;
          flex-direction: column;

          .sidebar-visits{
            min-width: 260px;
          }
        }


      }

    }
  }

  &.list-dashboard {

    .calendar-wrapper {

      width: calc( calc( 100vw / 3 ) * 2 );

    }

    .sidebar-wrapper {
      width: calc( 100vw / 3 );
    }

    @media (max-width: $size-xs-max) {
      .calendar-wrapper {
        width: 100%;
      }

      .sidebar-wrapper {
        display: none;
      }
    }
  }


  .job-list {
    border-top: 2px solid $secondary-pink;
    margin: 0 15px;
    padding: 10px 5px;
  }

}

.dashboard-calendar {

  body {

    &,
    #root {
      width: 100vw;
    }


    .page-wrapper {
      z-index: 5;

      @media(max-width: $size-xs){
        top:135px;
      }

      .calendar-wrapper {

      }

      .sidebar-contents {
        overflow-y: scroll;
        max-height: calc(100vh - 151px);
      }
    }


  }

}
