@import "../variables";

.visit {
  &.qa-enabled {
    background-color: #eeeeee !important;
  }

  & + .visit {
    margin-left: 5px;
  }

  // padding: 10px;
  border: 2px solid #e9e9e9;
  border-radius: 2px;
  display: block;
  box-sizing: border-box;

  &[data-visit-submitted="false"] {
    //outline: 1px dashed black;
  }

  &[data-dragging="true"] {
    opacity: 0.6;
  }

  &[data-drag-preview="true"] {
    border-color: rgba(0, 0, 0, 0.2);
    max-width: 150px;
    width: auto;
    background-color: white;
    transform: rotate(-1.25deg);

    .visit-product-remaining,
    .visit-controls {
      // display: none !important;
    }
  }

  .visit-inner {
    .visit-toolbar {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;
    }

    .visit-details {
      padding: 8px;
      .visit-product-remaining {
        // padding-bottom: 10px;
        .visit-product-list {
          padding-bottom: 10px;
          & > div {
          }
        }
      }
      .comment-section {
        .latest-comment-section {
          .button-section {
            .close-comment-button {
              width: 100%;
              margin-top: 5px !important;
              & > div {
                font-size: 12px;
                background-color: #eeeeee;
              }
            }
          }
        }

        .add-comment-section {
          .add-comment {
            & > div {
              font-size: 12px !important;
            }
          }
          .button-section {
            .add-comment-button {
              width: 100%;
              margin-top: 5px !important;
              & > div {
                font-size: 12px;
                background-color: #1eb3e7;
                color: white;
              }
            }
          }

          .comment-section {
            .article-comment-submit {
              & > div {
                font-size: 12px !important;
                textarea {
                  margin-top: 0px !important;
                  margin-bottom: 0px !important;
                }
              }
              .submit-button {
                margin-left: 0px;
                font-size: 12px;

                button {
                  background-color: #1eb3e7 !important;
                  color: white;
                }
              }
            }
          }
        }
      }

      .visit-applicator-section {
        display: flex;
        font-size: 12px;
        align-items: flex-start;
        .visit-applicator-name {
          flex: 1;
          font-size: 11px;
          line-height: 14px;
          padding-right: 5px;
          font-weight: bolder;
          display: flex;

          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .job-number {
            font-size: 11px;
            margin-top: 5px;
            margin-bottom: 5px;
            line-height: 13px;
            font-weight: bolder;
          }
        }
        .visit-qa-section {
          // flex:1;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          .qa {
            height: 32px;
            button {
              margin-top: -5px !important;
            }
          }
        }
      }

      .visit-name-section {
        display: flex;
        align-items: flex-start;
        padding-top: 3px;
        .visit-name {
          flex: 1;
          font-size: 15px;
          line-height: 17px;
          padding-right: 5px;
          font-weight: bolder;
        }

        .info-button {
          height: 32px;
          button {
            margin-top: -5px !important;
          }
        }
      }

      .visit-tags {
        display: flex;
        font-size: 11px;
        line-height: 13px;
        opacity: 0.8;
        margin: 5px 0;
        font-weight: bolder;
        flex-wrap: wrap;

        div {
          margin: 0 4px 4px 0;
        }
      }

      .visit-details-inner-section {
        display: flex;
        margin-bottom: 10px;
        .visit-details-inner-column {
          flex: 1;
          display: flex;
          flex-direction: column;

          .visit-details-inner-row-item {
            font-size: 11px;
            padding-top: 5px;
            padding-bottom: 5px;
            span {
              font-weight: bolder;
            }
          }
        }
      }
    }

    .visit-site-name {
      font-weight: bold;
    }

    .visit-recent-comment {
      font-size: 0.7em;
      padding-top: 5px;
      font-style: italic;
    }

    .visit-approval-message {
      font-size: 0.8em;
      padding-top: 15px;
      font-weight: bold;
    }

    .visit-product-list {
      display: flex;
      flex-direction: column;

      padding-top: 5px;
      font-size: 0.7em;
    }

    .visit-controls {
      border-top: 1px dashed rgba(0, 0, 0, 0.3);
      margin-top: 10px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.calendar-day-visits {
  .visit {
    & + .visit {
      margin-top: 5px;
      margin-left: 0px;
    }
  }
}
.calendar {
  .visit {
    .visit-controls {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.visit-page {
  .visit {
    & + .visit {
      margin-left: 0px;
      margin-top: 5px;
    }
  }

  .visit-page-header {
    position: sticky;
    top: 64px;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1000;
    margin-top: -10px;
    padding-top: 10px;
  }
}
